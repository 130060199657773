<template>
  <b-modal id="bv-modal-limits" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      <h5>Configurar limites</h5>
    </template>

    <b-tabs v-if="currencies.length || currWithOutLimits.length">
      <b-tab active v-if="currencies.length">
        <template v-if="isUpdating" #title>
          {{ isUpdating ? "Monedas con limites" : "Agregar limites" }}
        </template>
        <div class="row mb-4">
          <div class="my-3 row px-2">
            <div class="col-3 text-center"><strong>Moneda.</strong></div>
            <div class="col-3 text-center"><strong>Min. Bet</strong></div>
            <div class="col-3 text-center"><strong>Max. Bet</strong></div>
            <div class="col-3 text-center">
              <strong>Max. Position Bet </strong>
            </div>
          </div>
          <div v-if="currencies.length">
            <form @submit.prevent="save">
              <div v-for="(currency, index) in currencies" :key="index">
                <div class="card p-2">
                  <div class="row align-items-center">
                    <div class="col-3">
                      <p class="m-0">
                        Moneda: <strong>{{ currency.short }}</strong>
                      </p>
                    </div>
                    <div class="col-3">
                      <input @change="(e) => handleChange(e, currency)" :value="currency.minBet ? currency.minBet : 0"
                        type="number" class="form-control" name="minBet" placeholder="Min. Bet" minlength="1" required />
                    </div>
                    <div class="col-3">
                      <input @change="(e) => handleChange(e, currency)" :value="currency.maxBet ? currency.maxBet : 0"
                        type="number" class="form-control" name="maxBet" placeholder="Min. Bet" minlength="1" required />
                    </div>
                    <div class="col-3">
                      <input @change="(e) => handleChange(e, currency)" :value="
                        currency.maxBetPosition ? currency.maxBetPosition : 0
                      " type="number" class="form-control" name="maxBetPosition" placeholder="Max. Bet Position"
                        minlength="1" required />
                    </div>
                  </div>
                  <vs-button type="button" class="delete-limit" success @click="deleteLimit(currency)">
                    <span class="uil-trash-alt text-light"></span>
                  </vs-button>
                </div>
              </div>
              <div class="mb-3 mx-auto">
                <div class="d-flex justify-content-start">
                  <vs-button type="submit">
                    {{ isUpdating ? "Actualizar" : "Agregar" }}
                  </vs-button>
                  <vs-button @click="closeModa()" success type="button">
                    Cancelar
                  </vs-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-tab>
      <b-tab title="Monedas sin limites" v-if="currWithOutLimits.length">
        <div class="my-3 row px-2">
          <div class="col-3 text-center"><strong>Moneda.</strong></div>
          <div class="col-3 text-center"><strong>Min. Bet</strong></div>
          <div class="col-3 text-center"><strong>Max. Bet</strong></div>
          <div class="col-3 text-center">
            <strong>Max. Position Bet </strong>
          </div>
        </div>
        <div v-if="currWithOutLimits.length">
          <form @submit.prevent="saveNewLimits">
            <div v-for="( currency, index) in currWithOutLimits" :key="index">
              <div class="card p-2">
                <div class="row align-items-center">
                  <div class="col-3">
                    <p class="m-0">
                      Moneda: <strong>{{ currency.short }}</strong>
                    </p>
                  </div>
                  <div class="col-3">
                    <input @change="(e) => handleUpdate(e, currency)" type="number" class="form-control" name="minBet"
                      placeholder="Min. Bet" minlength="1" required />
                  </div>
                  <div class="col-3">
                    <input @change="(e) => handleUpdate(e, currency)" type="number" class="form-control" name="maxBet"
                      placeholder="Min. Bet" minlength="1" required />
                  </div>
                  <div class="col-3">
                    <input @change="(e) => handleUpdate(e, currency)" type="number" class="form-control"
                      name="maxBetPosition" placeholder="Max. Bet Position" minlength="1" required />
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 mx-auto">
              <div class="d-flex">
                <vs-button>Guardar</vs-button>
                <vs-button @click="closeModa()" success type="button">Cancelar</vs-button>
              </div>
            </div>
          </form>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";

export default {
  props: [
    "currencies",
    "gameId",
    "limitsByCurrencies",
    "isUpdating",
    "currWithOutLimits",
    "noLimitsCurrencies",
  ],
  data() {
    return {
      modal: false,
      typesubmit: false,
      typeform: {},
      limits: [],
      newLimits: [],
    };
  },
  methods: {
    async deleteLimit(currency) {
      const { isConfirmed } = await Swal.fire({
        title: "¿Estas seguro de eliminar este limite?",
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        icon: "info",
        confirmButtonColor: "primary",
        denyButtonColor: "secondary",
      });

      if (!isConfirmed) {
        return this.closeModa();
      }

      const id = currency._id;
      const { data } = await this.$http.delete(
        `dragon-tiger/${id}/delete-currency-limits`
      );

      if (!data.ok) {
        return Swal.fire("Error eliminando limite");
      }

      this.closeModa();
      return Swal.fire("Limite eliminado", "", "success");
    },

    async save() {
      if (this.isUpdating) {
        const { data } = await this.$http.put(
          `dragon-tiger/${this.gameId}/update-currency-limits`,
          {
            limits: this.limits,
          }
        );
        if (data.ok) {
          this.closeModa();
          return Swal.fire({
            title: "Limites actualizados",
            icon: "success",
            timer: 1000,
          });
        }

        return Swal.fire({
          title: "Error actualizando limites",
          icon: "error",
          timer: 1000,
        });
      } else {



        const data = await this.addLimits({ path: "dragon-tiger", id: this.gameId, limits: this.limits })

        if (data.ok) {
          this.closeModa();
          return Swal.fire({
            title: "Limites agregados",
            icon: "success",
            timer: 1500,
            position: "center",
          });
        }
        return Swal.fire({
          title: data.msg,
          icon: "info",
          timer: 1500,
          position: "center",
        });
      }
    },
    async saveNewLimits() {

      try {
        const { data } = await this.$http.post(
          `dragon-tiger/${this.gameId}/add-currency-limits`,
          {
            limits: this.newLimits,
          }
        );
        if (data.ok) {
          this.closeModa();
          return Swal.fire({
            title: "Limites agregados",
            icon: "success",
            timer: 1500,
            position: "center",
          });
        }
        return Swal.fire({
          title: data.msg,
          icon: "info",
          timer: 1500,
          position: "center",
        });
      } catch (err) {
        console.log("Error guardando nuevos limites", err);
        return Swal.fire({
          title: "Error guardando limites",
          icon: "info",
          timer: 1500,
          position: "center",
        });
      }
    },
    closeModa() {
      this.typeform = {};

      this.modal = false;

      this.$emit("closeModa");
    },
    handleChange(e, currency) {
      const { value, name } = e.target;

      const currencyIndex = this.getIndex(this.limitsByCurrencies, currency);

      this.limits[currencyIndex] = {
        ...this.limits[currencyIndex],
        short: currency.short,
        currency: currency.currency,
        [name]: value,
      };


    },
    handleUpdate(e, currency) {
      const { value, name } = e.target;

      const currencyIndex = this.getIndex(this.noLimitsCurrencies, currency);


      this.newLimits[currencyIndex] = {
        ...this.newLimits[currencyIndex],
        short: currency.short,
        currency: currency.currency,
        [name]: value,
      };


    },
    getIndex(limits, currency) {
      let currencyIndex = 0;

      limits.filter((curr, index) => {

        if (curr.currency === currency.short) {
          return (currencyIndex = index);
        }
        return "";
      });
      return currencyIndex;
    },
    ...mapActions({
      addLimits: "currencies/addLimits"
    })
  },
};
</script>

<style scoped>
.delete-limit {
  position: absolute;
  right: -10px;
  top: 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
}
</style>
